.connectionContainer{
    width: 100vw;
    padding-bottom: 56.25%;
    height: 0;
    position: relative;
    overflow: hidden;
    display: flex;

    & img{
        position: absolute;
        flex: 1;
        width: 100%;
        filter: brightness(75%) blur(5px);
    }

    & .container {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        justify-content: center;
        font-size: 5cqh;
        align-items: center;
        color: #E4C67F;

        & input {
            display: flex;
            z-index: 1;
            width: 15%;
            height: 8%;
            background-color: #2B3B76;
            outline: none;
            border: solid #E4C67F 2px;
            border-radius: 2cqh;
            font-size: 5cqh;
            text-align: center;
            color: #E4C67F;
            padding: 1cqh 0;
            font-family: "Acme-Regular";
            margin-top: 5cqh;
        }
    }
}

.error{
    height: 2cqh;
    color: red;
    font-size: 2cqh;
    font-weight: normal;
}

.buttonWrapper {
    margin-top: 10cqh;
    height: 15cqh;
    & button {
        background-color: #E4C67F;
        outline: none;
        border: none;
        border-radius: 20px;
        font-size: 3cqh;
        text-align: center;
        color: #2B3B76;
        font-weight: bold;
        padding: 1cqh 3cqh;
        font-family: "Acme-Regular";
    }

    & button:hover{
        background-color: #E4C67FCF;
    }

    & button:active{
        background-color: #E4C67F5F;
    }
}